.results-diseases {
	margin: 0.5rem 0;
	width: 100%;
	height: auto;
	background-color: rgb(240, 240, 240);
}

.deseases {
	text-align: left;
	text-decoration: underline;
	margin-left: 3%;
	cursor: pointer;

	>a {
		color: black;
	}
}

.lower-part {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.possibility {
	color: black;
	width: 120px;
	height: auto;
	float: left;
	margin-left: 3%;
	border-radius: 2px;
	font-size: 16px;
}

.button {
	margin-right: 3%;
}

button {
	border-radius: 20px;
	height: 30px;
	width: 150px;
	border: none;
	color: white;
	background-color: #009DDC;
	cursor: pointer;
	font-size: 16px;
	// @media screen and (max-width: 428px) {
	// 	font-size: 16px;
	// }
}

button:hover {
	background-color: #00779e;
	color: white;
}

.red {
	background-color: rgb(255, 130, 130);
}

.orange {
	background-color: rgb(255, 210, 106);
}

.gray {
	background-color: rgb(202, 202, 202);
}
