.scanner {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	text-align: center;

	margin: 0 auto;
	// height: 100vh;
	// max-height: 700px;
	max-width: 550px;

	@media screen and (max-width: 428px) {
		@include mobile();
		font-size: 14px;
	}

	.scanner-picture {
		cursor: pointer;
		margin: 0;
		width: 100%;
		max-width: 400px;
		// max-width: 362px;
		// max-width: 550px;
		min-height: 350px;

		@media screen and (max-width: 428px) {
			min-height: 300px;
		}
	}

	h1 {
		margin: 0px;
		font-size: 50px;

		@media screen and (max-width: 428px) {
			font-size: 36px;
		}
	}

	h5 {
		margin: 0px;
	}

	h4 {
		width: 100%;
		// max-width: 338px;
	}

	&-picture {
		background-color: #FFFCE8;
		border-radius: 22px;
		width: 100%;
		max-width: 700px;

		&-container {
			width: 100%;
			// max-width: 362px;
			max-width: 700px;
			// height: 392px;
			max-height: 500px;
			min-height: 300px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			// display: grid;
			// grid-template-columns: 1fr;
			// grid-template-rows: 2fr 1fr;

			&-buttons {
				// grid-row-start: 2;
				// grid-row-end: 2;
				display: flex;
				flex-direction: row-reverse;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;

				>button {
					margin: 5px;
					font-size: 16px;
				}
			}

			&-img {
				justify-self: center;
				align-self: center;
				max-width: 200px;
				width: 100%;

				// @media screen and (max-width: 428px) {
				// 	// @include mobile();
				// 	max-width: 200px;
				// }
			}

			&-caption {
				justify-self: center;
				align-self: center;
				font-size: 26px;
				font-weight: bold;
			}

			&-rules {
				// position: absolute;

				width: 100%;
				max-width: 700px;
				// max-width: 362px;

				// z-index: 4;
				@media screen and (max-width: 428px) {
					max-width: 300px;
				}

				>img {

					// z-index: 4;
					width: 100%;

				}
			}
		}

		&-container:first-child:nth-last-child(1) {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
		}

		&-container:first-child:nth-last-child(2)~&-container {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
		}

		.hide {
			display: none;
		}

		.image-input {
			display: none;
		}
	}

	&-information {
		background-color: #E2E2E2;

		width: 100%;
		// max-width: 362px;
		max-width: 700px;
		height: 52.84px;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		text-align: center;

		// margin: 28px 0 36.16px 0;

		// >h5 {
			// width: 100%;
			// max-width: 225px;
			// max-width: 500px;
		// }
	}

	&-terms {
		width: 100%;
		// max-width: 328px;
		max-width: 700px;

		margin: 2rem 0;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;

		font-weight: bold;

		&-link,
		&-link:visited {
			font-weight: bold;
			color: #000;
		}
	}
}
