@mixin desktop() {
	max-width: 1000px;
	width: 100%;
}

@mixin tablet() {
	max-width: 600px;
	width: 100%;
}

@mixin mobile() {
	max-width: 400px;
	width: 100%;
}
