@import "./_mixin.scss";
@import "./stages/index.scss";
@import "./components/index.scss";

html {
	margin: 0;
	padding: 0;
	// max-height: 100vh;
	// overflow: hidden;
}

.app{
  height: 100%;
  width: 100%;
}
