.results {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 0 auto;

	>h1 {
		margin: 0px;
		font-size: 50px;

		@media screen and (max-width: 428px) {
			font-size: 36px;
		}
	}

	>h4 {
		margin: 5px;
		font-weight: 300;
	}

	>h5 {
		margin: 5px;
	}
}

.results-info-container {
	display: flex;
	flex-direction: column;
	// width: 90%;
	width: 100%;
}

.results-info {
	display: flex;
	flex-direction: row;
	// margin: 0% 5% 5%;
	margin: 0;
	align-items: center;
	background-color: rgb(240, 240, 240);
	border-radius: 5px;
	padding-left: 5px;
	text-align: center;

	>img {
		width: 40px;
		height: 40px;
	}

	>p {
		font-size: 16px;
		margin: 5px;
		font-weight: 600;
		text-align: left;
	}
}


.down-buttons{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 80%;
	max-width: 400px;

	.ask-dermatologist {
		>a {
			>button {
				width: 100%;
				max-width: 300px;
				height: 48px;
				font-size: 24px;

				@media screen and (max-width: 280px) {
					font-size: 20px;
				}
			}
		}
	}

	.start-again {
		>button {
			font-size: 16px;
		}
	}
}
