.loading {
	width: 100%;
	max-width: 428px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 0 auto;

	&-picture {
		width: 100%;
		max-width: 428px;
	}
}
img {
    width: 100%;
	max-width: 428px;
    height: auto;
}
